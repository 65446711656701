import { Link } from 'react-router-dom'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import ProfilePic from '../../assets/images/me_AI.jpg'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const firstNameArray = ['A', 'd', 'o', 'n', 'a', 'i']
  const lastNameArray = ['A', 'd', 'd', 'o']
  const jobArray = [
    'S',
    'o',
    'f',
    't',
    'w',
    'a',
    'r',
    'e',
    '',
    'E',
    'n',
    'g',
    'i',
    'n',
    'e',
    'e',
    'r',
    '',
    '|',
    '',
    'P',
    'r',
    'o',
    'd',
    'u',
    'c',
    't',
    '',
    'M',
    'a',
    'n',
    'a',
    'g',
    'e',
    'r',
  ]

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className="container home-page">
        <img className="profile_picture" src={ProfilePic} alt="Adonai" />
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span> <nbsp />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={firstNameArray}
              idx={14}
            />{' '}
            <nbsp />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={lastNameArray}
              idx={19}
            />
          </h1>
          <h2>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={3}
            />
          </h2>
          <Link to="/contact" className="flat-button">
            SAY HI!😀
          </Link>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Home
